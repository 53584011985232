<template>
    <div class="login">
        <h5header :name="$t('sign.desc58')" :right="false" />
        <div class="login-con flex">
            <div class="login-left">
                <div class="left-name">{{ $t('sign.desc3') }}</div>
                <div class="left-title">{{ $t('sign.desc4') }}</div>
                <img src="../../public/images/new/loginimg.png" alt="" />
            </div>
            <div class="login-right">
                <div class="right-name">{{ $t('sign.desc58') }}</div>
         
              
                <div class="right-title">{{ $t('sign.desc59') }}</div>
                <div class="right-input flex">
                    <input type="text" :placeholder="$t('sign.desc21')" v-model="account"
                        onkeyup="value=value.replace(/[\u4e00-\u9fa5]/ig,'')" />
                    <img src="../../public/images/new/x.png" alt="" v-if="account != ''" @click="handlerClear(1)" />
                </div>
              
                <!-- <div class="right-title">{{ $t('sign.desc24') }}</div>
                <div class="right-input flex">
                    <input type="text" :placeholder="$t('sign.desc25')" v-model="code"
                        onkeyup="this.value=this.value.replace(/[^\w_]/g,'');" maxlength="6" />
                    <p @click="handlerSend">{{ sendText }}</p>
                </div> -->
             
                <div class="login-btn flexcenter" @click="handlerSubmit" v-loading="loading">{{ $t('home.desc20') }}</div>
              

            </div>
        </div>
        <van-popup v-model:show="showInfo" round :close-on-click-overlay="false">
            <div class="modal-info">
                <div class="info-name">{{ $t('sign.desc60') }}</div>
                <div class="info-desc">{{ $t('sign.desc61') }}</div>
                <div class="info-btn flexcenter" @click="$router.go(-1)">{{ $t('sign.desc62') }}</div>
            </div>
        </van-popup>
           
        
    </div>
</template>

<script>

import h5header from '@/componets/h5header.vue';
export default {
    components: {
        h5header
    },
    data() {
        return {
            showInfo:false,
            check: true,
            accountName: '',
            account: '',
            loginPassword: '',
            conpassword: '',
            code: '',
            inviteCode: '',
            sendText: this.$t('sign.desc26'),
            timeNull: null,
            issend: true,
            loading: false,
            psdtype1:'password',
            psdtype:'password'
        }
    },

    mounted() {

    },
    methods: {
        handlerEye(i) {
            if (i == 0) {
                this.psdtype == 'password' ? this.psdtype = 'text' : this.psdtype = 'password'
            } else {
                this.psdtype1 == 'password' ? this.psdtype1 = 'text' : this.psdtype1 = 'password'
            }
        },
        handlerSend() {
            let reg = /^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z0-9]{2,5}$/, _this = this;

            if (this.account == '') {
                this.$message.error(this.$t('sign.desc21'));
                return;
            }
            if (!reg.test(this.account)) {
                this.$message.error(this.$t('sign.desc35'));
                return;
            }
            if (this.issend) {
                this.issend = false;

                this.$post(this.URL.login.sendverify, {
                    accountType: 2,
                    account: this.account,
                    type:7
                }).then(res => {
                    if (res.code == 0) {
                        this.$message.success(this.$t('sign.desc37'));
                        let num = 60
                        this.timeNull = setInterval(() => {
                            num--;
                            if (num <1) {
                                clearInterval(_this.timeNull);
                                _this.issend = true;
                                _this.sendText = _this.$t('sign.desc26')
                            } else {
                                this.sendText = `${num} S`
                            }
                        }, 1000)
                    } else {
                        this.issend = true;
                    }
                })

            }
        },
        handlerClear(i) {
            i == 0 ? this.accountName = '' : this.account = '';
        },
        handlerCheck() {
            this.check ? this.check = false : this.check = true;
        },
        handlerSubmit() {
            let reg = /^[A-Za-z0-9]+([-_.][A-Za-z0-9]+)*@([A-Za-z0-9]+[-.])+[A-Za-z0-9]{2,5}$/,reg1=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d).*$/;
       
            if (this.account == '') {
                this.$message.error(this.$t('sign.desc21'));
                return;
            }
            if (!reg.test(this.account)) {
                this.$message.error(this.$t('sign.desc35'));
                return;
            }
         
            // if (this.code == '') {
            //     this.$message.error(this.$t('sign.desc25'));
            //     return;
            // }
            if(this.loading){
                return;
            }
            this.loading = true;
            this.$post(this.URL.login.account, {
                accountType: 2,
                account: this.account
            }).then(res => {
                if (res.code == 0) {
                  this.showInfo=true;
                    
                } else {
                    this.loading = false;
                    this.$message.error(res.message)
                }

            })
        },
      
    },
    destroyed () {
        clearInterval(this.timeNull)
    }
}
</script>

<style lang="less" scoped>
.login {
    padding: 156px 0 117px;
    min-height: 875px;
    background: url('../../public/images/new/loginbg.png') center no-repeat;
    background-size:cover;
    background-color: #000000;

    .login-con {
        width: 1240px;
        margin: 0 auto;
        justify-content: space-between;

        .login-left {
            position: relative;
            flex: 0 0 620px;
            padding-top: 40px;
            color: #FFFFFF;
            min-height: 689px;
            .left-name {
                margin-bottom: 20px;
                font-size: 30px;

            }

            .left-title {
                font-size: 20px;
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 736px;
                height: 689px;
            }

        }

        .login-right {
            flex: 0 0 480px;
            padding: 40px;
            height: fit-content;
            background: rgba(24, 24, 24, 0.8);
            border-radius: 12px;
            color: #fff;

            .right-name {
                padding-bottom: 4px;
                font-size: 20px;
            }

            .right-title {
                padding: 20px 0 12px;
                font-size: 14px;
                line-height: 14px;
            }

            .right-tips {
                margin-top: 10px;
                font-size: 12px;
                color: #EB4343;
            }
            .rright-tips1{
                margin-top: 10px;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.6);
            }

            .right-input {
                position: relative;
                padding: 15px 16px;
                border-radius: 6px;
                border: 1px solid #393939;
                box-sizing: border-box;

                &:hover {
                    border: 1px solid #C9FA5B;
                }

                img {
                    width: 16px;
                    height: 16px;
                    margin-left: 16px;
                    cursor: pointer;
                }

                input {
                    flex: 1;
                    height: 16px;
                    font-size: 14px;
                    color: #fff;

                    &::placeholder {
                        color: #5F5F5F;
                    }
                }

                p {
                    cursor: pointer;
                    color: #C9FA5B;
                }
            }

            .right-forgot {
                margin-top: 12px;
                justify-content: space-between;
                line-height: 16px;

                div {
                    font-size: 13px;

                    img {
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                        cursor: pointer;
                    }
                }

                p {
                    font-size: 14px;
                    color: #C9FA5B;
                    cursor: pointer;

                }
            }

            .login-btn {
                margin-top: 32px;
                height: 55px;
                background: url('../../public/images/new/btnbg12.png') center no-repeat;
                background-size: 100% 100%;
                cursor: pointer;
                font-size: 18px;
                color: #000000;
            }

            .login-tips {
                margin: 20px 0 0;
                justify-content: center;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.6);

                p {
                    color: #C9FA5B;
                    cursor: pointer;
                }
            }

            
        }
    }
}
.modal-info{
    width: 400px;
    padding: 32px;
    .info-name{
        font-size: 20px;
        color: #FFFFFF;
        text-align: center;
    }
    .info-desc{
        padding: 20px 0 24px;
        font-size: 16px;
        color: #A8A8A8;
 
    }
    .info-btn{
        height: 57px;
        background: url('../../public/images/new/btnbg20.png') center no-repeat;
        background-size: 100% 100%;
        font-size: 18px;
        color: #000000;
        cursor: pointer;
    }
  
}
@media (max-width:1200px) {
    .login {
        background: #000000;
        
        padding: 108px 0;
        .login-con {
            width: 100%;
            justify-content: center;

            .login-left {
                display: none;
            }

            .login-right {}
        }
    }
}

@media (max-width:767px) {
    .login {
        padding: 56px 0;
        min-height:inherit;
        .login-con {
            width: calc(100vw - 8px);

            .login-right {
                flex: 0 0 100%;
                width: 100%;
                padding: 20px 16px;

                .right-name {
                    font-size: 16px;
                }

                .right-title {
                    padding: 16px 0;
                }

                .right-input {
                    padding: 12px 15px;
                }

                .login-btn {
                    margin-top: 24px;
                    font-size: 16px;
                }

                .login-tips {
                    margin: 12px 0 0;
                }
            }
        }
    }
    .modal-info{
        width: calc(100vw - 8px);
        .info-name{
            font-size: 16px;
        }
        .info-desc{
            font-size: 14px;
            padding: 10px 0 12px;
        }
        .info-btn{
            font-size: 14px;
        }
    }
}
</style>